/* Constants */
.red-font {
    color: #ec412c !important;
}

.red-background {
    background-color: #ec412c !important;
}


.bg-video-banner {
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: -999;
}


.feature-banner-img{
    border-radius: 20px; 
    object-fit: cover
}

.feature-text-div{
    position: fixed;
    left: 50%;
    bottom: 20px;
    transform: translate(-50%, -50%);
    margin: 0 auto;
}

.game-modal{
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
}

.btn-close {
    color: #fff !important; 
    opacity: 1;
}



.video-player{
  aspect-ratio: 5/4;
  border-radius: 23px;
  object-fit: cover;
}






.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }
  
  .modal-main {
    position:fixed;
    background: white;
    width: 80%;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
  }
  
  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }


  .img-zoom{

  }

  .img-zoom:hover{
    transform: scale(1.1);

  }



  /* TabTag */
  .tab{
    color: whitesmoke;
  }



/* Games Grid -- Next & Prev Buttons CSS */

.pagination-button button{
  background-color: var(--red) !important;
  border: 2px solid white !important;
  color: white !important 
}
.pagination-button button:hover{
  background: rgb(220 53 69 / 85%);
}
.pagination-button button:focus{
   outline: 3px solid rgb(220 53 69 / 50%);
  
}
.pagination-button div{
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}



.my-tabs {
  font-size: 24px;
  color: var(--semi-translucent);
}

.my-tabs .MuiTab-root {
  background-color: transparent;
  border-radius: 10px;
  padding: 8px 16px;
  margin-right: 10px;
  font-size: 16px;
  color: var(--semi-translucent);
  border-bottom: 2px solid transparent;
}

.my-tabs .MuiTab-root.Mui-selected {
  background-color: transparent;
  color: var(--red);
  border-bottom-color: transparent;
}


.my-tabs .MuiTab-root .MuiTab-wrapper {
  border-bottom: 2px solid transparent;
}

.my-tabs .MuiTab-root.Mui-selected .MuiTab-wrapper {
  border-bottom-color: transparent;
}
.my-tabs .MuiTabs-indicator {
  background-color: var(--red);
}




#secondary {
  position: absolute;
  top: 57%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dropdown-accent{
  position: absolute;
  top: 12px;
  right: 45px;
  height: 20px;
  width: 40px;
  border-radius: 10px;
  border: solid white 2px;
}