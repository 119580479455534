.panel-profile-pic {
    /* border-radius: 50%; */
    border-radius: 23px
        /* max-height: 200px;
    max-width: 200px; */
}


.panel-container {
    width: 100%;
    height: 400px;
    position: relative;
    /* margin: 30px; */
}

.panel-box {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7;
    /* background: #0057e3; */
}

.panel-overlay {
    z-index: 9;
    margin: 30px;
    /* background: #009938; */
}


.panel-profile-cover {
    /* background-image: url('../../../public/images/Park_Idea1.png'); */
    /* object-fit: cover !important; */

}