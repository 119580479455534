.s-signup-container {
    text-align: center;
    /* margin-top: 90px !important; */
}

.red-background {
    background-color: #ec412c !important;
}
.s-signup-content {
    background: var(--secondary);
    box-shadow: 0 1px 11px rgba(0, 0, 0, 0.27);
    border-radius: 20px;
    width: 500px;
    display: inline-block;
    margin-top: 30px;
    vertical-align: middle;
    position: relative;
    padding: 35px;
}

.s-social-btn {
    margin-bottom: 15px;
    font-weight: 400;
    font-size: 16px;
}

.s-social-btn img {
    height: 32px;
    float: left;
    margin-top: 10px;
}

.s-social-btn.google {
    margin-top: 7px;
}

.s-social-btn.facebook img {
    height: 24px;
    margin-left: 3px;
}

.s-social-btn.github img {
    height: 24px;
    margin-left: 3px;
}

.s-login-link {
    color: whitesmoke;
    font-size: 14px;
    margin-top: 100px;
}

.s-signup-title {
    font-size: 1.5em;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 30px;
    color: white !important;
}

.zaroori li{
    float: left;
}


.zaroori li div{
    margin-left: 40px;
}

 

.facebook-btn{
    background-color: #3b5998;
     color: white;
    border-radius: 6px;
    padding: 10px;
    width: 80px;
}



.google-btn{
    background-color: #e72734;
    color: white;
    border-radius: 6px;
    padding: 10px;
    width: 80px;
}


.github-btn{
    background-color: #fff;
    color: black;
    border-radius: 6px;
    padding: 10px;
    width: 80px;
}



