body {
  margin: 0;
  padding: 0;
  height: 100%;
}



:root {

    --red: #ec412c;
    --primary: #1F2122;
    --secondary: #27292A;
    --supporting: white;
    --semi-translucent: #888;


    /* --red: #5C7E10;
    --primary: #1B2839	;
    --secondary: #223D58; */
    

}



.primary-bg{
    background-color: var(--primary);
}

.secondary-bg{
    background-color: var(--secondary);
}

.red-bg{
    background-color: var(--red);
}

.supporting-bg{
  background-color: var(--supporting);
}

.supporting-fg{
  color: var(--supporting) !important;
}

.semi-translucent-fg{
  color: var(--semi-translucent) !important;
}


.codetest-check {
    padding-bottom: 56.25%;
    position: relative;
    display: block;
    width: 100%
}



.codetest-check2 {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}


.appheader-margin{
    margin-top: 100px;
    margin-bottom: 20px;
}



/* Shadow Effects */

.shadow-bg{
  box-shadow: 0 1px 11px rgba(0, 0, 0, 0.27) !important;
}



.shadow-bg:hover{
  box-shadow: 0 1px 11px whitesmoke !important;
}


.hover-me:hover{
  transform: scale(1.1);
  color: white !important;
  cursor: pointer;
}







/* animations */
.slide-in-bottom{
    opacity: 0;
  transform: translateY(-100%);
  animation: slide-in-bottom 0.5s forwards;
  /* animation-delay: 2s */
}

.slide-in-bottom-item{
  opacity: 0;
  transform: translateY(100%);
  animation: slide-in-bottom 0.5s forwards;
  animation-delay: 1s
}


.slide-in-bottom-3s{
  opacity: 0;
  transform: translateY(100%);
  animation: slide-in-bottom 0.5s forwards;
  animation-delay: 1s
}


@keyframes slide-in-bottom {
    100% {
      opacity: 1;
      transform: translateY(0);
    }
}

.fade-in {
  opacity: 0;
  animation: fade-in 0.5s forwards;
  animation-delay: 0.5s
}


.fade-in-2s {
  opacity: 0;
  animation: fade-in 0.5s forwards;
  animation-delay: 2s
}

@keyframes fade-in {
  100% {
    opacity: 1;
  }
}


.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px; /* adjust to the desired width */
}