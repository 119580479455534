/* Example Styles for React Tags*/
/* .app {
    padding: 40px;
    text-align: center;
  } */
  
  /* Example Styles for React Tags*/
  
  /* .container {
    margin: auto;
    width: 50%;
  } */
  .ReactTags__tags {
    position: relative;
  }
  
  /* Styles for the input */
  .ReactTags__tagInput {
    width: 400px;
    border-radius: 2px;
    display: inline-block;
    
  }
  .ReactTags__tagInput input.ReactTags__tagInputField,
  .ReactTags__tagInput input.ReactTags__tagInputField:focus {
    height: 40px;
    margin: 0;
    font-size: 12px;
    width: 100%;
    border: 2px solid ;
    border-radius: 4px;
    padding: 10px;
    background-color: var(--primary);
    color: white;
    margin-top: 10px;
  }
  
  /* Styles for selected tags */
  .ReactTags__selected span.ReactTags__tag {
    border: 2px solid white;
    background: var(--red);
    color: white;
    font-size: 12px;
    display: inline-block;
    padding: 5px;
    margin: 0 5px;
    border-radius: 2px;
  }

  .ReactTags__selected a.ReactTags__remove {
    color: #aaa;
    margin-left: 5px;
    cursor: pointer;
  }

  
  /* Styles for suggestions */
  .ReactTags__suggestions {
    position: absolute;
    background-color: var(--primary);
    color: white;
    border: 2px solid white;
  }
  .ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
    background: var(--primary);
    width: 400px;
    border-radius: 2px;
  }
  .ReactTags__suggestions li {
    border-bottom: 1px solid white;
    padding: 5px 10px;
    margin: 0;
  }

  /* For Underlined Entered Bold Text */
  .ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
    color: red;
  }
  .ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: rgba(56, 56, 56, 0.507);
    cursor: pointer;
  }
  
  .ReactTags__remove {
    border: none;
    cursor: pointer;
    background: none;
    color: white;
  }
  