.btn-panel{
    /* margin-top: 2px; */
    background-color: #27292A !important;
    border-color: transparent !important;
    color: white !important;
    border-radius: 20px !important;
}


.app-header-img {
    max-width: 30px;
  border-radius: 50%;
  margin-left: 5px;
}

.btn-panel-dropdown{
    background-color: #27292A !important;
    /* border-color: transparent !important; */
    color: red important;
}


.btn-panel:hover{
    /* background-color: transparent !important; */
    /* border-color: transparent !important; */
    /* color: black; */
}



/* .dropdown{
    background-color: gray;
} */


.dropdown_header{
    /* background-color: gray; */
    font-weight: bold;
    font-size: medium;
    padding-bottom: 0;
    padding-top: 0;
    /* margin: 0; */
}


.offcanvas {
    color: white;
    background-color: var(--primary);
}


.dropdown-opener{
    border: 2px solid #4f5253 !important;
    background-color: var(--primary);
}


/* .z-appheader{
    z-index:9999999 !important;
} */