.icons-container {
    padding: 50px;
}




.icon-p {
    margin-left: 40px;
}


.iframe-div {
    padding: 10px;
    padding-bottom: 56.25%;
    box-sizing: border-box;
    resize: horizontal;
    overflow: auto;
    max-width: 100%;
    height: 560px;
}



/* Using */

html {
    scroll-behavior: smooth;
}

.fixed-size {
    height: 540px;
    width: 960px;
    /* webkit-border-radius: 20px; */
    /* -moz-border-radius: 20px; */
    border-radius: 20px;
    margin: 0 auto;
    overflow: hidden;
}


.icon-background-container {
    height: 100px;
    /* position: relative; */
    /* background-color: #1F2122; */
    color: white;

}

.centerer {
    text-align: center;
    margin: 0 auto;
}


.avatar {
    vertical-align: middle;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.arrow-icon-background {
    width: 100px;
    height: 100px;
    /* display: table; */
    border-radius: 50%;
    background-color: #1f2122;
    padding: 37px;
    /* vertical-align: middle; */
}

.arrow-icon-background:hover {
    background-color: #ec412c;
}


.detail-background {
    background-color: #1f2122 !important;
}

.detail-back-background {
    background-color: #27292a !important;
}