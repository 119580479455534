.videoWrapper {
	position: relative;
	padding-bottom: 56.25%;
	/* padding-top: 25px; */
	height: 0;
	/* background-color: aliceblue; */
	z-index: 0;
}

.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 0%;
	margin: 0%;
	z-index: 0;
	
	


	/* height: 0;
    width: 0;
    border: 0;
    box-sizing: border-box;
    max-height: 100%;
    max-width: 100%;
    min-height: 100%;
    min-width: 100%; */
}


.videoWrapper i {
	position: absolute;
	right: 0;
	bottom: 0;
	color: yellow;
	/* background-color: aquamarine; */
	/* z-index: 99999; */

}





.gameWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
    padding: 0%;
    margin: 0%;
	/* height: 100%; */
	/* width: 100%; */
	/* border: 0;
	box-sizing: border-box;
	max-height: 100%;
	max-width: 100%;
	min-height: 100%;
	min-width: 100%; */
}

.screenshots {
	border-radius: 23px;
	margin-bottom: 30px;
}


.fa:hover{
	transform: scale(1.4);
	cursor: pointer;
}



.fullscreen-iframe{
	display: block;
	border: none;
	height: 100vh; 
	width: 100vw;
}


.copy-clipboard-button{
	position: relative;
	
}
.copy-clipboard-button div{
	position:absolute;
	right: 1px;
	top: 4px;
	/* border:none; */
	/* height:20px; */
	/* width:20px; */
	/* border-radius:100%; */
	/* outline:none; */
	/* text-align:center; */
	font-weight:bold;
	padding:4px;
	margin-right: 4px;
	cursor: pointer;
	/* margin-bottom: 4px; */
  }

/* "border-radius: 23px; margin-bottom: 30px;" */



.tag-style{
	background-color: var(--red);

}


.swap-icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 24px;
    color: #ffffff;
    cursor: pointer;
    z-index: 9999;
    opacity: 0; /* Start with opacity 0 */
    transition: opacity 0.3s ease-in-out; /* Add a transition effect */
  }

  .swap-icon.visible {
    opacity: 1; /* Show the swap icon */
  }