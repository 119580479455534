

.rich-wrapper{
    background-color: white;
    min-height: 150px;
    border-radius: 20px !important;    
}

.rich-editor{
    height: 250px;
    /* min-height: 200px;
    max-height: 500px; */
    background-color: whitesmoke;
    border-radius: 20px;
    padding-left: 20px;
    padding-right: 20px;
    display: block;

}


.public-DraftStyleDefault-ul,
.public-DraftStyleDefault-ol {
  display: block !important;
  list-style-type: disc !important; /* or 'decimal' for ordered lists */
  margin-left: 1em !important; /* or adjust to your preference */
  
}


.rich-editor ol li {
    display: list-item !important;
    float: none !important;
}

.bg-dark{
    background-color: var(--primary) !important;
}

.btn{
    background-color: var(--red) !important;
    border: 2px solid white !important;
    color: white !important 
}

.rich-toolbar{
    border-radius: 20px;
    border-width: 5px;
}



.rti--container {
    background-color: transparent;
    /* --rti-bg: "#fff",
        --rti-border: "#ccc",
        --rti-main: "#3182ce",
        --rti-radius: "0.375rem",
        --rti-s: "0.5rem",
        
        --rti-tag: "#edf2f7",
        --rti-tag-remove: "#e53e3e", */
}

/* 
.Editor ol li {
    display: list-item;
} */



.upload-form {
    border-radius: 40px;
    background-color: var(--secondary);
    box-shadow: 0 1px 11px rgba(0, 0, 0, 0.27) !important;
    /* border-radius: 2px; */
}

.form-background {
    background-color: #27292A;

}

.cover-div {
    position: relative;
    width: 315px;
    height: 250px;
}

.cover-div-border {
    /* background-color: red; */
    border-color: whitesmoke;
    border-width: 1px;
    border-radius: 40px;
    border: white;
    border-style: solid;
    background-color: #27292A;
}

.cover-div {
    /* width: 315px; */
    height: 250px;
    
    /* background-color: red; */
    border-color: whitesmoke;
    border-width: 1px;
    /* border-radius: 40px; */
    border: white;
    border-style: solid;
    display: none;
}



.placeholderImage{
    height: 200px;
    line-height: 200px;
    border: whitesmoke;
    border-width: 2px;
    border-style: solid;

}


.profile-img-div {
    width: 250px !important;
    height: 250px !important;
    /* background-color: red; */
    /* border-color: whitesmoke;
    border-width: 1px;
    border-radius: 40px;
    border: white;
    border-style: solid;
    display: none; */
}

.profile-img-div img {
    width: 250px !important;
    height: 250px !important;
    /* background-color: red; */
    /* border-color: whitesmoke;
    border-width: 1px;
    border-radius: 40px;
    border: white;
    border-style: solid;
    display: none; */
}


.uplBtn {
    position: absolute;
    top: 45%;
    left: 50%;
    width: 200px;
    transform: translate(-80%, -250%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: white;
    font-size: 16px;
    padding: 12px 24px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}


.screenshot-btn {
    /* position: absolute; */
    /* top: 45%; */
    /* left: 50%; */
    /* width: 200px; */
    /* transform: translate(-80%, -250%); */
    /* -ms-transform: translate(-50%, -50%); */
    background-color: #555;
    color: white;
    font-size: 16px;
    padding: 12px 24px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

/* .cover-div input {
    margin-top: 100px;
} */


.cover-div label {
    padding: 10px;
    background: red;
    display: table;
    color: #fff;
    border-radius: 10px;
    margin-top: 100px;
    margin-left: 60px;
    border: 1px solid #fff ;
}


.primary-bg label {
    padding: 10px;
    background: red;
    display: table;
    color: #fff;
    border-radius: 10px;
    border: 1px solid #fff ;
    /* margin-top: 100px;
    margin-left: 60px; */
}




.cover-div input[type="file"] {
    display: none;
}


.primary-bg input[type="file"] {
    display: none;
}


.text-align-center {
    text-align: center;
}

.progress {
    height: fit-content;
    /* padding: 4px; */
    /* height: 100%; */
    background: rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
}

.progress-bar {
    /* width: 13.543%; */
    /* height: 25px; */
    background-color: green !important;
    border-radius: 4px;
    background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
    background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
    background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
    -webkit-transition: 0.4s linear;
    -moz-transition: 0.4s linear;
    -o-transition: 0.4s linear;
    transition: 0.4s linear;
    -webkit-transition-property: width, background-color;
    -moz-transition-property: width, background-color;
    -o-transition-property: width, background-color;
    transition-property: width, background-color;
    -webkit-box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.25), inset 0 1px rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.25), inset 0 1px rgba(255, 255, 255, 0.1);
}

#filesBox {
    display: flex;
    flex-wrap: 'wrap';
    justify-content: 'space-between';
}

.file {
    width: 'calc(50% - 20px)'; /* Adjust the width as per your preference */
    box-sizing: 'border-box';
    display: 'flex';
    flex-direction: 'column';
    align-items: 'center';
    margin: '10px';
  }


  .progress-bar-border{
    border: #fff solid 3px;
    border-radius: 10px;

  }